// Page Background
import bg_page_608 from "Assets/images/bg_page_608.png";
import bg_page_903 from "Assets/images/bg_page_903.png";
import bg_page_1237 from "Assets/images/bg_page_1237.png";
import bg_page_1400 from "Assets/images/bg_page_1400.png";
export var bg_page = {
  src: bg_page_1400,
  title: "Seiten Hintergrund",
  alt: "Hintergrund",
  height: 2206,
  width: 1400,
  sources: [{
    media: "(min-width: 1200px)",
    srcset: bg_page_1400
  }, {
    media: "(min-width: 900px)",
    srcset: bg_page_1237
  }, {
    media: "(min-width: 600px)",
    srcset: bg_page_903
  }, {
    media: "(max-width: 599.98px)",
    srcset: bg_page_608
  }]
}; // Stage Background Big

import bg_stageCropped_600 from "Assets/images/bg_stage--big-cropped_600.png";
import bg_stageBig_970 from "Assets/images/bg_stage--big_970.png";
import bg_stageBig_1251 from "Assets/images/bg_stage--big_1251.png";
import bg_stageBig_1400 from "Assets/images/bg_stage--big_1400.png";
export var bg_stageBig = {
  src: bg_stageBig_1400,
  title: "Stage Sonnenuntergang",
  alt: "Sonnenuntergang Hintergrund",
  width: 1400,
  height: 788,
  sources: [{
    media: "(min-width: 1200px)",
    srcset: bg_stageBig_1400
  }, {
    media: "(min-width: 900px)",
    srcset: bg_stageBig_1251
  }, {
    media: "(min-width: 600px)",
    srcset: bg_stageBig_970
  }, {
    media: "(max-width: 599.98px)",
    srcset: bg_stageCropped_600
  }]
}; // Stage Background Small

import bg_stageSmall_771 from "Assets/images/bg_stage--small_771.png";
import bg_stageSmall_948 from "Assets/images/bg_stage--small_948.png";
import bg_stagSmall_1400 from "Assets/images/bg_stage--small_1400.png";
export var bg_stageSmall = {
  src: bg_stageBig_1400,
  title: "Stage Sonnenuntergang",
  alt: "Sonnenuntergang Hintergrund",
  width: 1400,
  height: 394,
  sources: [{
    media: "(min-width: 1200px)",
    srcset: bg_stagSmall_1400
  }, {
    media: "(min-width: 900px)",
    srcset: bg_stageSmall_948
  }, {
    media: "(min-width: 600px)",
    srcset: bg_stageSmall_771
  }, {
    media: "(max-width: 599.98px)",
    srcset: bg_stageCropped_600
  }]
}; // Menu Mobile Background

import bg_menu_300 from "Assets/images/bg_menu_cropped_300.png";
export var bg_mobile_menu = {
  src: bg_menu_300,
  title: "Hintergrund",
  alt: "Menu Background",
  width: 300,
  height: 300,
  sources: [{
    media: "",
    srcset: bg_menu_300
  }]
}; // Book cover 01

import pic_bookCover_441 from "Assets/images/pic_buchfront_441.png";
export var pic_bookCover = {
  src: pic_bookCover_441,
  title: "Verschleppt! im Netz der Delphinräuber",
  alt: "Stage book cover",
  width: 700,
  height: 441,
  sources: [{
    media: "",
    srcset: pic_bookCover_441
  }]
}; // Dolphin 01

import pic_dolphin_01_720 from "Assets/images/pic_delfin--01_720.png";
import pic_dolphin_01_1088 from "Assets/images/pic_delfin--01_1088.png";
export var pic_dolphin_01 = {
  src: pic_dolphin_01_1088,
  title: "Delphin",
  alt: "Stage Dolphin",
  width: 1088,
  height: 436,
  sources: [{
    media: "(min-width: 1200px)",
    srcset: pic_dolphin_01_1088
  }, {
    media: "(max-width: 1199.98px)",
    srcset: pic_dolphin_01_720
  }]
}; // Dolphin 02

import pic_dolphin_02_671 from "Assets/images/pic_delfin--02_671.png";
import pic_dolphin_02_1200 from "Assets/images/pic_delfin--02_1200.png";
export var pic_dolphin_02 = {
  src: pic_dolphin_02_1200,
  title: "Delphin",
  alt: "Stage Dolphin",
  width: 1200,
  height: 512,
  sources: [{
    media: "(min-width: 601px)",
    srcset: pic_dolphin_02_1200
  }, {
    media: "(max-width: 600px)",
    srcset: pic_dolphin_02_671
  }]
}; // Bird 01

import pic_bird_01_500 from "Assets/images/pic_vogel--01_550.png";
export var pic_bird_01 = {
  src: pic_bird_01_500,
  title: "See Möwe",
  alt: "Bird Picture",
  width: 550,
  height: 328,
  sources: [{
    media: "",
    srcset: pic_bird_01_500
  }]
}; // Bird 02

import pic_bird_02_839 from "Assets/images/pic_vogel--02_839.png";
export var pic_bird_02 = {
  src: pic_bird_02_839,
  title: "See Möwe",
  alt: "Bird Picture",
  width: 839,
  height: 800,
  sources: [{
    media: "",
    srcset: pic_bird_02_839
  }]
}; // Bird 03

import pic_bird_03_171 from "Assets/images/pic_vogel--03.png";
export var pic_bird_03 = {
  src: pic_bird_03_171,
  title: "See Möwe",
  alt: "Stage Bird",
  width: 171,
  height: 194,
  sources: [{
    media: "",
    srcset: pic_bird_03_171
  }]
}; // Beawer 02

import pic_beawer_02_483 from "Assets/images/pic_beawer--02_483.png";
export var pic_beawer_02 = {
  src: pic_beawer_02_483,
  title: "Beawer um die Ecke",
  alt: "Beawer",
  width: 483,
  height: 717,
  sources: [{
    media: "",
    srcset: pic_beawer_02_483
  }]
}; // Beawer 03

import pic_beawer_03_483 from "Assets/images/pic_beawer--03_636.png";
export var pic_beawer_03 = {
  src: pic_beawer_03_483,
  title: "Beawer mit der Zunge",
  alt: "Beawer",
  width: 636,
  height: 800,
  sources: [{
    media: "",
    srcset: pic_beawer_03_483
  }]
}; // Shipwreck

import pic_ship_1400 from "Assets/images/pic_ship--01--cropped_1400.png";
import pic_ship_1224 from "Assets/images/pic_ship--01--cropped_1224.png";
import pic_ship_948 from "Assets/images/pic_ship--01--cropped_948.png";
import pic_ship_668 from "Assets/images/pic_ship--01--cropped_668.png";
export var pic_ship = {
  src: pic_ship_1400,
  title: "Großes Schiff",
  alt: "Schriff",
  width: 1400,
  height: 1482,
  sources: [{
    media: "(min-width: 1200px)",
    srcset: pic_ship_1400
  }, {
    media: "(min-width: 900px)",
    srcset: pic_ship_1224
  }, {
    media: "(min-width: 600px)",
    srcset: pic_ship_948
  }, {
    media: "(max-width: 599.98px)",
    srcset: pic_ship_668
  }]
}; // Author

import author from "Assets/images/pic_author--01.png";
export var pic_author = {
  src: author,
  title: "Der junge Author, Günter Strobl",
  alt: "Author",
  width: 202,
  height: 300,
  sources: [{
    media: "",
    srcset: author
  }]
};