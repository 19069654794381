function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export function capitalizeFirstLetter(string) {
  if (string.length === 0) {
    return false;
  }

  return string.replace(string[0], string[0].toUpperCase());
}
export function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}
export function toLocalDate(date, locale) {
  if (date.length === 0 || locale === "") {
    return "";
  }

  var dateModel = new Date(date);
  var day = dateModel.toLocaleDateString(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  var time = dateModel.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit"
  });
  return "".concat(day, " / ").concat(time);
}
export function objectIsEmpty(obj) {
  for (var i in obj) {
    return false;
  }

  return true;
}
export function isObject(value) {
  return _typeof(value) === "object";
}
export function scrollToTop(behavior) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: behavior ? behavior : "auto"
  });
}